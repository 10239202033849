.marquee {
  --marquee-duration: 30s;
  --marquee-delay: 2s;

  border-top: 1px solid var(--stroke);
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;

  transform: translateY(100%);
}

.marquee[active="true"] {
  animation: calc(var(--marquee-duration) + (var(--marquee-delay) * 2)) linear 1
    both running showHidemarquee;
}

.content {
  /* background-color: yellow; */
  display: inline-block;

  height: var(--marquee-height);
  position: absolute;
  left: 100%;
}

.marquee[active="true"] .content {
  will-change: transform;
  animation: var(--marquee-duration) linear 2s 1 forwards running marquee;
}

@keyframes showHidemarquee {
  0% {
    transform: translateY(100%);
  }
  1% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(-0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
