:root {
  --primary-color: #387c6a;
  --primary-color--darker: #226b5e;

  --text: #414255;
  --text-bright: #787987;

  --stroke: #e9edf0;
  --stroke-dark: var(--text);

  --highlight: #e9edf0;

  /* --background: #f9f7f6; */
  --background: #ffffff;

  /* Sizes */
  --max-size: 800px;

  /* Spacing */
  --spacing: 20px;
  --stroke-width: 1px;

  /* Animations */
  --rapid: 0.1s;
  --fast: 0.3s;
  --slow: 0.5s;

  /* Typography */
  --font-family-sans-serif: "semplicitapro", Arial, sans-serif;
  --font-family-serif: "Cormorant", Georgia, serif;
  --font-family-display: "semplicitapro", Arial, sans-serif;

  /* Letter spacing */
  --letter-spacing-tight: 0.025em;
  --letter-spacing-spacious: 0.075em;

  /* Presentation */
  --presentation-stroke: #cccccc;
}
