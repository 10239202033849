.input {
  font-family: var(--font-family-sans-serif);
  font-weight: 500;
  font-size: calc(16rem / 16);
  line-height: calc(24rem / 16);
  padding-top: 20px;
  padding-left: 4px;

  display: block;
  border: none;
  color: inherit;
  background-color: transparent;

  width: 100%;
}

.input:focus {
  outline: none;
}
.select:focus {
  outline: none;
}
.select:focus::-moz-focus-inner {
  border: 0;
}
.select::-moz-focus-inner {
  border: 0;
}

.select {
  /* Reset default styling */
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding-left: 0;
  /* Reset default styling - END */

  color: inherit;
  background: url(../../../images/icons/arrow-small__down--green.svg) no-repeat
    100% 50%;

  font-family: var(--font-family-sans-serif);
  font-weight: 500;
  font-size: calc(16rem / 16);
  line-height: calc(24rem / 16);
  margin-top: 19px;
  width: 100%;
  cursor: pointer;

  padding-left: 4px;
  /* text-indent: 10px; */
}

.file {
  display: none;
}

.file + .label {
  cursor: pointer;
}

.imageLabel {
  width: 50%;
  max-width: 200px;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;
}

.label {
  display: block;
  width: 100%;
}

.labelText {
  font-weight: 100;
  letter-spacing: var(--letter-spacing-spacious);
  font-size: calc(16rem / 16);
  line-height: calc(24rem / 16);
  color: var(--text-bright);
  cursor: text;
  padding-left: 6px;

  position: absolute;
  left: 0;
  top: 20px;

  transition: transform var(--rapid);
  transform-origin: 0 50%;
}

.labelLine {
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--stroke);
  position: relative;
}

.labelLine::before,
.labelLine::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform var(--rapid) var(--rapid);
}

.labelLine::before {
  background-color: var(--primary-color);
}

.labelLine::after {
  background-color: red;
}

.input:focus + .label .labelLine::before,
.select:focus + .label .labelLine::before {
  transform: scaleX(1);
  will-change: transform;
}

.input:focus + .label .labelText,
.input:not(:placeholder-shown) + .label .labelText,
.select + .label .labelText {
  transform: translateY(-100%) scale(0.875);
}

/* If input field has not been filled out */
.input:placeholder-shown + .label .labelText,
.input:placeholder-shown + .label .labelLine {
  will-change: transform;
}

/* Errors */

.error {
  visibility: hidden;
  color: red;
  font-size: calc(12rem / 16);
  line-height: calc(16rem / 16);
  min-height: calc(2 * (16rem / 16));
  width: 100%;
  margin-left: 11px;
}

.error[data-show-error="true"] {
  visibility: visible;
}

.input:invalid {
  /* Reset default styling */
  box-shadow: none;
  /* Reset default styling - END */
}

.input:invalid + .label .labelLine::after,
.select:invalid + .label .labelLine::after {
  transform: scaleX(1);
  will-change: transform;
}
