.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px;
}

.decoration {
  display: block;
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.intro {
  font-family: var(--font-family-serif);
  font-style: italic;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 6px;
}

.heading {
  font-size: calc(22rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}

.subHeading {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}

.description {
  font-size: calc(14rem / 16);
  line-height: calc(20rem / 16);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.headingContainer[presentation-mode="true"] .intro {
  font-size: calc(20rem / 16);
  line-height: calc(24rem / 16);
}

.headingContainer[presentation-mode="true"] .heading {
  font-size: calc(40rem / 16);
  line-height: calc(50rem / 16);
}

.headingContainer[presentation-mode="true"] .description {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
  margin-top: 10px;
  margin-bottom: 0;
}
