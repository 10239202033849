.spinner {
  --spinner-size: 100px;
  --border-size: 10px;
  --spinner-color: var(--primary-color);
  --track-color: var(--stroke);

  position: relative;
  width: var(--spinner-size);
  height: var(--spinner-size);
  margin-top: 50px;

  animation: entry var(--fast) 1s backwards;
}

@keyframes entry {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.spinner__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transform-origin: 50% 50%;
  animation: rotate 1s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

.spinner__innersecondary {
  z-index: 2;
  animation: rotate 1s cubic-bezier(0.65, 0.05, 0.36, 1) 0.1s infinite;
}

.spinner__front,
.spinner__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: var(--border-size) solid var(--track-color);
  border-radius: 50%;
  box-sizing: border-box;
}

.spinner__front {
  border-color: var(--spinner-color);
  clip-path: inset(0px 50px 50px 0px); /* Fallback */
  clip-path: inset(
    0px calc(var(--spinner-size) / 2) calc(var(--spinner-size) / 2) 0px
  );
  z-index: 2;
}

.spinner__back {
  z-index: 1;
}

.spinner__dot {
  position: absolute;
  z-index: 3;
  border-radius: 50%;
  width: var(--border-size);
  height: var(--border-size);
  background: var(--spinner-color);
}

.spinner__dot1 {
  top: 0;
  left: 50%;

  transform: translateX(-50%);
}

.spinner__dot2 {
  top: 50%;
  left: 0;

  transform: translateY(-50%);
}
