@import url(https://use.typekit.net/xws4exx.css);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.FadeIn_fadeInAppear__2q4rF {
  opacity: 0.01;
}

.FadeIn_fadeInAppear__2q4rF.FadeIn_fadeInAppearActive__1e8jz {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
}

.Button_button__Y_MZR {
  --button-color: white;
  --button-background: var(--primary-color);
  --button-background-hover: var(--primary-color--darker);
  --button-background-disabled: var(--text-bright);

  background-color: var(--button-background);
  color: var(--button-color);
  border: none;
  font-size: calc(14rem / 16);
  line-height: calc(18rem / 16);
  padding: 16px 50px;
  border-radius: 100px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: var(--letter-spacing-spacious);
  font-family: var(--font-family-display);
  display: inline-block;

  -webkit-transition: background-color var(--slow), -webkit-transform var(--fast);

  transition: background-color var(--slow), -webkit-transform var(--fast);

  transition: transform var(--fast), background-color var(--slow);

  transition: transform var(--fast), background-color var(--slow), -webkit-transform var(--fast);
}

.Button_button__Y_MZR:not([priority="secondary"]) + .Button_button__Y_MZR {
  margin-top: 10px;
}

.Button_button__Y_MZR[appearance="big"] {
  font-size: calc(18rem / 16);
  line-height: calc(24rem / 16);
}

.Button_button__Y_MZR[priority="secondary"] {
  background-color: transparent;
  color: var(--text);
  font-size: calc(14rem / 16);
  line-height: calc(18rem / 16);
  border-radius: 0;
  text-decoration: underline;
  text-transform: none;
  padding: 8px 20px;
}

.Button_button__Y_MZR:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-transition: background-color var(--rapid), -webkit-transform var(--rapid);
  transition: background-color var(--rapid), -webkit-transform var(--rapid);
  transition: transform var(--rapid), background-color var(--rapid);
  transition: transform var(--rapid), background-color var(--rapid), -webkit-transform var(--rapid);
}

.Button_button__Y_MZR[disabled]:hover,
.Button_button__Y_MZR[priority="secondary"]:hover {
  -webkit-transform: none;
          transform: none;
}

.Button_button__Y_MZR:not([priority="secondary"]):hover,
.Button_button__Y_MZR:not([priority="secondary"]):focus {
  background-color: var(--button-background-hover);
  outline: none;
}

.Button_button__Y_MZR::-moz-focus-inner {
  border: 0;
}

.Button_button__Y_MZR:not([priority="secondary"]):disabled {
  background-color: var(--button-background-disabled);
  cursor: default;
}

.Button_button__Y_MZR[priority="secondary"]:disabled {
  color: var(--stroke);
  cursor: default;
}

/* Container */
.Section_container__2Xz6t {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.Section_container__2Xz6t[is-first-on-page="true"] {
  margin-top: 0;
}

/* Group */
.Section_group__3By2Y {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  max-width: calc(100% - (var(--spacing) * 2));
  margin-top: 20px;
}

.Section_group__3By2Y:first-of-type {
  margin-top: 0;
}

.Section_group__3By2Y .Section_section__23hAB {
  width: calc((100% / 2) - (var(--spacing) / 2));
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}

/* Section */
.Section_section__23hAB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  width: 500px;
  max-width: calc(100% - (var(--spacing) * 2));
  position: relative;
}

.Section_section__23hAB:first-of-type {
  margin-top: 0;
}

.Section_section__23hAB:last-of-type .Section_button__slIqB:not([priority="secondary"]) {
  margin-top: 30px;
}

.Section_section__23hAB [spacing="narrow"] {
  margin-top: 0;
}

.Paragraph_paragraph__Kq178 {
  font-size: calc(16rem / 16);
  line-height: calc(30rem / 16);
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 50px;

  max-width: var(--max-size);
  margin-right: auto;
  margin-left: auto;
}

.Paragraph_paragraph__Kq178 + .Paragraph_paragraph__Kq178 {
  margin-top: 20px;
}

.Paragraph_paragraph__Kq178[align="left"] {
  text-align: left;
  padding-right: 0;
  padding-left: 0;
}

.Landing_landing__3dsMi {
  height: 100vh;
  min-height: 640px;
  position: relative;
  overflow: hidden;
}

.Landing_logo__3umdD {
  width: 200px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Landing_backgroundColor__Uez42 {
  /* background-color: #f0f1f3; */
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.1);
  margin: calc(var(--spacing) / 2);
  height: calc(100% - (var(--spacing) / 2) * 2);
  width: calc(100% - (var(--spacing) / 2) * 2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Landing_vineBottomRight__1kctA {
  position: absolute;
  right: -240px;
  bottom: -10px;
  width: 200px;
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

.Landing_vineTopLeft__PdVzp {
  position: absolute;
  left: -56px;
  top: -40px;
  width: 200px;
  -webkit-transform: rotate(337deg);
          transform: rotate(337deg);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
}

/* Animation */

.Landing_vineBottomRightAppear__R2Gsa {
  -webkit-transform: rotate(80deg);
          transform: rotate(80deg);
}

.Landing_vineBottomRightAppear__R2Gsa.Landing_vineBottomRightAppearActive__2f5kL {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-transition: -webkit-transform var(--slow) var(--fast);
  transition: -webkit-transform var(--slow) var(--fast);
  transition: transform var(--slow) var(--fast);
  transition: transform var(--slow) var(--fast), -webkit-transform var(--slow) var(--fast);
}

.Landing_vineTopLeftAppear__2anYP {
  -webkit-transform: rotate(377deg);
          transform: rotate(377deg);
}

.Landing_vineTopLeftAppear__2anYP.Landing_vineTopLeftAppearActive__2-eDn {
  -webkit-transform: rotate(337deg);
          transform: rotate(337deg);
  -webkit-transition: -webkit-transform var(--slow) var(--fast);
  transition: -webkit-transform var(--slow) var(--fast);
  transition: transform var(--slow) var(--fast);
  transition: transform var(--slow) var(--fast), -webkit-transform var(--slow) var(--fast);
}

.Header_header__2Z8R_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(var(--spacing) * -1);
  margin-right: calc(var(--spacing) * -1);
  margin-left: calc(var(--spacing) * -1);
  padding: 20px;
}

.Header_link__3EafT {
  min-height: 30px;
  padding: 10px;
  margin: -10px;
  display: block;
  cursor: pointer;

  -webkit-transition: -webkit-transform var(--fast);

  transition: -webkit-transform var(--fast);

  transition: transform var(--fast);

  transition: transform var(--fast), -webkit-transform var(--fast);
}

.Header_link__3EafT:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: -webkit-transform var(--rapid);
  transition: -webkit-transform var(--rapid);
  transition: transform var(--rapid);
  transition: transform var(--rapid), -webkit-transform var(--rapid);
}

.Header_icon__2nITR {
  width: 30px;
  height: 30px;
  display: block;
  object-fit: contain;
}

.Header_iconArrow__2-nu4 {
  width: 25px;
}

.BeverageBrief_beverageBrief__1udMS {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.BeverageBrief_type__1nS-- {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  display: block;
  object-fit: contain;
  width: 40px;
  height: 40px;
}

.BeverageBrief_heading__BdWmX {
  font-size: calc(24rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.BeverageBrief_subHeading__1feoo {
  font-family: var(--font-family-serif);
  font-style: italic;
  font-size: calc(20rem / 16);
  line-height: calc(24rem / 16);
  margin-top: 0;
  margin-bottom: 0;
}

.BeverageBrief_beverageBrief__1udMS[appearance="small"] {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}

[appearance="small"] .BeverageBrief_type__1nS-- {
  margin-bottom: 5px;
}

[appearance="small"] .BeverageBrief_heading__BdWmX {
  color: var(--primary-color);
  font-size: calc(16rem / 16);
  line-height: calc(20rem / 16);
  margin-top: 6px;
  margin-bottom: 6px;
}

[appearance="small"] .BeverageBrief_subHeading__1feoo {
  font-family: var(--font-family-serif);
  font-size: calc(16rem / 16);
  line-height: calc(20rem / 16);
  margin-top: 4px;
}

.Rating_rating__1vyeu {
}

.Rating_rating__1vyeu[is-locked="true"] {
  pointer-events: none;
}

.Rating_text__2AbZo {
  font-size: calc(14rem / 16);
  line-height: calc(16rem / 16);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-spacious);
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.Rating_ratings__q26Fl {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Rating_units__3MXyt {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: left;
  display: flex;
}

.Rating_unit__3Dc2D {
  display: block;
  position: relative;
  width: 1.1em;
  cursor: pointer;
  background: url(/static/media/heart.82d777c3.svg) no-repeat center / contain;
  height: 25px;
  width: 25px;
  padding-right: 6px;
  padding-left: 6px;
  box-sizing: content-box;
}

.Rating_unit__3Dc2D::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(/static/media/heart-filled.180d9114.svg) no-repeat center /
    contain;
  opacity: 0;

  -webkit-transition: opacity var(--rapid);

  transition: opacity var(--rapid);
}

.Rating_units__3MXyt[data-simple="true"] {
  margin-top: 10px;
}

.Rating_units__3MXyt[data-simple="true"] .Rating_unit__3Dc2D {
  height: 15px;
  width: 15px;
  padding-right: 2px;
  padding-left: 2px;
  background-image: url(/static/media/heart--blue.df206eff.svg);
}

.Rating_rating__1vyeu[is-locked="true"] .Rating_unit__3Dc2D {
  background-image: url(/static/media/heart--blue.df206eff.svg);
}

.Rating_units__3MXyt[data-simple="true"] .Rating_unit__3Dc2D::after,
.Rating_rating__1vyeu[is-locked="true"] .Rating_unit__3Dc2D::after {
  background-image: url(/static/media/heart-filled--blue.7309c29c.svg);
}

/* Only show rated hearts when mouse is not hovering */
.Rating_units__3MXyt:not(:hover) .Rating_unit__3Dc2D[rated="true"]::after,
.Rating_units__3MXyt:not(:hover) .Rating_unit__3Dc2D[rated="true"] ~ .Rating_unit__3Dc2D::after,


.Rating_units__3MXyt[data-simple="true"] .Rating_unit__3Dc2D[rated="true"]::after,
.Rating_units__3MXyt[data-simple="true"] .Rating_unit__3Dc2D[rated="true"] ~ .Rating_unit__3Dc2D::after,


.Rating_units__3MXyt:not([data-simple="true"]) .Rating_unit__3Dc2D:hover::after,
.Rating_units__3MXyt:not([data-simple="true"]) .Rating_unit__3Dc2D:hover ~ .Rating_unit__3Dc2D::after {
  opacity: 1;
}

.Rating_average__10oWb {
  font-size: calc(12rem / 16);
  line-height: calc(16rem / 16);
  display: block;
  margin-top: 0;
  margin-left: 4px;
  margin-bottom: 0;
}

.Rating_units__3MXyt[data-simple="true"] + .Rating_average__10oWb {
  margin-top: 10px;
}

.BeveragesItem_beveragesItem__1Tm4Y + .BeveragesItem_beveragesItem__1Tm4Y {
  border-top: 1px solid var(--stroke);
}

.BeveragesItem_link__2b4y5 {
  color: var(--text);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  -webkit-transition: background-color var(--fast);

  transition: background-color var(--fast);
}

.BeveragesItem_link__2b4y5:hover {
  background-color: var(--highlight);

  -webkit-transition: background-color var(--rapid);

  transition: background-color var(--rapid);
}

.BeveragesItem_bottle__2EDEa {
  display: block;
  width: 60px;
  height: 120px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  object-fit: contain;
}

.BeveragesItem_info__2GRi4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Min-width should match var(--max-size) */
@media screen and (min-width: 1024px) {
  .BeveragesItem_emptySpace__2OmfV {
    width: 60px;
    height: 120px;

    flex-grow: 0;
    flex-shrink: 0;
  }
}

.BeveragesList_beveragesList__1mlGH {
  list-style: none;
  padding: 0;
  margin: 0;

  max-width: var(--max-size);
  margin-right: auto;
  margin-left: auto;
}

.SpinnerAnimation_spinner__1dsai {
  --spinner-size: 100px;
  --border-size: 10px;
  --spinner-color: var(--primary-color);
  --track-color: var(--stroke);

  position: relative;
  width: var(--spinner-size);
  height: var(--spinner-size);
  margin-top: 50px;

  -webkit-animation: SpinnerAnimation_entry__2wLlg var(--fast) 1s backwards;

          animation: SpinnerAnimation_entry__2wLlg var(--fast) 1s backwards;
}

@-webkit-keyframes SpinnerAnimation_entry__2wLlg {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes SpinnerAnimation_entry__2wLlg {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.SpinnerAnimation_spinner__inner__12yjl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  -webkit-transform-origin: 50% 50%;

          transform-origin: 50% 50%;
  -webkit-animation: SpinnerAnimation_rotate__2XRb2 1s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
          animation: SpinnerAnimation_rotate__2XRb2 1s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}

@-webkit-keyframes SpinnerAnimation_rotate__2XRb2 {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}

@keyframes SpinnerAnimation_rotate__2XRb2 {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}

.SpinnerAnimation_spinner__innersecondary__3tp-r {
  z-index: 2;
  -webkit-animation: SpinnerAnimation_rotate__2XRb2 1s cubic-bezier(0.65, 0.05, 0.36, 1) 0.1s infinite;
          animation: SpinnerAnimation_rotate__2XRb2 1s cubic-bezier(0.65, 0.05, 0.36, 1) 0.1s infinite;
}

.SpinnerAnimation_spinner__front__38AzM,
.SpinnerAnimation_spinner__back__13931 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: var(--border-size) solid var(--track-color);
  border-radius: 50%;
  box-sizing: border-box;
}

.SpinnerAnimation_spinner__front__38AzM {
  border-color: var(--spinner-color);
  -webkit-clip-path: inset(0px 50px 50px 0px);
          clip-path: inset(0px 50px 50px 0px); /* Fallback */
  -webkit-clip-path: inset(
    0px calc(var(--spinner-size) / 2) calc(var(--spinner-size) / 2) 0px
  );
          clip-path: inset(
    0px calc(var(--spinner-size) / 2) calc(var(--spinner-size) / 2) 0px
  );
  z-index: 2;
}

.SpinnerAnimation_spinner__back__13931 {
  z-index: 1;
}

.SpinnerAnimation_spinner__dot__1gOxv {
  position: absolute;
  z-index: 3;
  border-radius: 50%;
  width: var(--border-size);
  height: var(--border-size);
  background: var(--spinner-color);
}

.SpinnerAnimation_spinner__dot1__GL6Xz {
  top: 0;
  left: 50%;

  -webkit-transform: translateX(-50%);

          transform: translateX(-50%);
}

.SpinnerAnimation_spinner__dot2__3xhDU {
  top: 50%;
  left: 0;

  -webkit-transform: translateY(-50%);

          transform: translateY(-50%);
}

.Spinner_text__2lk56 {
  padding-right: 20px;
  padding-left: 20px;
}

.Spinner_appear__3egkD {
  opacity: 0.01;
}

.Spinner_appear__3egkD.Spinner_appearActive__183d3 {
  opacity: 1;
  -webkit-transition: opacity 0.25s 1s ease-in;
  transition: opacity 0.25s 1s ease-in;
}

.Error_error__ZyF0t {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.Error_text__36xde {
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.Error_details__NRu_P {
  font-size: calc(12rem / 16);
  line-height: calc(20rem / 16);
}

.Error_appear__xPD87 {
  opacity: 0.01;
}

.Error_appear__xPD87.Error_appearActive__O3X5R {
  opacity: 1;
  -webkit-transition: opacity 0.25s 0.25s ease-in;
  transition: opacity 0.25s 0.25s ease-in;
}

.Divider_divider__3OOtm {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.Divider_ruler__1kGsG {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: calc(2 * var(--stroke-width));
  border: none;
  background-color: var(--stroke);
  margin-top: auto;
  margin-bottom: auto;
}

.Divider_divider__3OOtm[unobtrusive="true"] {
  margin-top: -10px;
  margin-bottom: -10px;
}

.Divider_divider__3OOtm[appearance="faint"] .Divider_ruler__1kGsG {
  height: var(--stroke-width);
  background-color: var(--stroke);
}

.Divider_divider__3OOtm[appearance="thin"] .Divider_ruler__1kGsG {
  height: var(--stroke-width);
}

.Divider_divider__3OOtm[narrow="true"] {
  max-width: var(--max-size);
  margin-right: auto;
  margin-left: auto;
}

.Divider_divider__3OOtm[spacing="spacious"] {
  margin-top: 25px;
  margin-bottom: 25px;
}

.Divider_mask__2-WTZ {
  text-align: center;
  position: relative;
  background-color: var(--background);
  padding-right: 20px;
  padding-left: 20px;
}

.Divider_image__v3Y_d {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  display: block;
  width: 50px;
  height: 50px;
}

.Divider_divider__3OOtm[appearance="thin"] .Divider_image__v3Y_d {
  width: 40px;
  height: 40px;
}

.Divider_text__3QQGM {
  font-size: calc(16rem / 16);
  line-height: calc(22rem / 16);
  font-weight: 600;
  display: block;
}

.Divider_divider__3OOtm[presentation-mode="true"] {
  margin-top: 0px;
}

[presentation-mode="true"] .Divider_image__v3Y_d {
  width: 80px;
  height: 80px;
}

[presentation-mode="true"] .Divider_ruler__1kGsG {
  height: 1px;
  background-color: #ccc;
}

.Heading_headingContainer__1IBq1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px;
}

.Heading_decoration__12zfE {
  display: block;
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.Heading_intro__2icY5 {
  font-family: var(--font-family-serif);
  font-style: italic;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 6px;
}

.Heading_heading__mkd9j {
  font-size: calc(22rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}

.Heading_subHeading__3BNxF {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}

.Heading_description__24o2r {
  font-size: calc(14rem / 16);
  line-height: calc(20rem / 16);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.Heading_headingContainer__1IBq1[presentation-mode="true"] .Heading_intro__2icY5 {
  font-size: calc(20rem / 16);
  line-height: calc(24rem / 16);
}

.Heading_headingContainer__1IBq1[presentation-mode="true"] .Heading_heading__mkd9j {
  font-size: calc(40rem / 16);
  line-height: calc(50rem / 16);
}

.Heading_headingContainer__1IBq1[presentation-mode="true"] .Heading_description__24o2r {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
  margin-top: 10px;
  margin-bottom: 0;
}

.BeverageBottle_bottle__3PKsd {
  background-color: white;
  margin-right: calc(var(--spacing) * -1);
  margin-left: calc(var(--spacing) * -1);
  position: relative;
  height: var(--image-height);
  cursor: pointer;
}

.BeverageBottle_bottleImage__39Apc {
  display: block;
  width: 50%;
  height: 100%;
  object-fit: contain;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transition: -webkit-transform var(--fast);
  transition: -webkit-transform var(--fast);
  transition: transform var(--fast);
  transition: transform var(--fast), -webkit-transform var(--fast);
}

.BeverageBottle_bottle__3PKsd[data-zoom-bottle="true"] .BeverageBottle_bottleImage__39Apc {
  -webkit-transform: scale(2);
          transform: scale(2);
}

.BeverageBottle_vines__1VKQX {
  width: 100%;
  height: calc(100% + 100px);
  position: absolute;
  overflow: hidden;
}

.BeverageBottle_vine__2U_bf {
  position: absolute;
  right: -250px;
  bottom: 120px;
  width: 200px;
  z-index: 1;
  -webkit-transform: rotate(105deg);
          transform: rotate(105deg);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transition: -webkit-transform var(--slow);
  transition: -webkit-transform var(--slow);
  transition: transform var(--slow);
  transition: transform var(--slow), -webkit-transform var(--slow);
}

.BeverageBottle_vine2__yMBpI {
  position: absolute;
  left: -230px;
  bottom: 160px;
  width: 200px;
  z-index: 1;
  -webkit-transform: rotate(-100deg);
          transform: rotate(-100deg);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
  -webkit-transition: -webkit-transform var(--slow);
  transition: -webkit-transform var(--slow);
  transition: transform var(--slow);
  transition: transform var(--slow), -webkit-transform var(--slow);
}

.BeverageBottle_bottle__3PKsd[data-zoom-bottle="true"] .BeverageBottle_vine__2U_bf {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.BeverageBottle_bottle__3PKsd[data-zoom-bottle="true"] .BeverageBottle_vine2__yMBpI {
  -webkit-transform: rotate(-80deg);
          transform: rotate(-80deg);
}

/* Animation */

.BeverageBottle_vineAppear__2LtXv .BeverageBottle_vine__2U_bf {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.BeverageBottle_vineAppear__2LtXv .BeverageBottle_vine2__yMBpI {
  -webkit-transform: rotate(-80deg);
          transform: rotate(-80deg);
}

.Quiz_quiz__2aMQ0 {
  text-align: center;
}

.Form_input__2v4BS {
  font-family: var(--font-family-sans-serif);
  font-weight: 500;
  font-size: calc(16rem / 16);
  line-height: calc(24rem / 16);
  padding-top: 20px;
  padding-left: 4px;

  display: block;
  border: none;
  color: inherit;
  background-color: transparent;

  width: 100%;
}

.Form_input__2v4BS:focus {
  outline: none;
}
.Form_select__tPhnL:focus {
  outline: none;
}
.Form_select__tPhnL:focus::-moz-focus-inner {
  border: 0;
}
.Form_select__tPhnL::-moz-focus-inner {
  border: 0;
}

.Form_select__tPhnL {
  /* Reset default styling */
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding-left: 0;
  /* Reset default styling - END */

  color: inherit;
  background: url(/static/media/arrow-small__down--green.bf25e080.svg) no-repeat
    100% 50%;

  font-family: var(--font-family-sans-serif);
  font-weight: 500;
  font-size: calc(16rem / 16);
  line-height: calc(24rem / 16);
  margin-top: 19px;
  width: 100%;
  cursor: pointer;

  padding-left: 4px;
  /* text-indent: 10px; */
}

.Form_file__1ULgC {
  display: none;
}

.Form_file__1ULgC + .Form_label__1H3hN {
  cursor: pointer;
}

.Form_imageLabel__39hlo {
  width: 50%;
  max-width: 200px;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;
}

.Form_label__1H3hN {
  display: block;
  width: 100%;
}

.Form_labelText__2NJYk {
  font-weight: 100;
  letter-spacing: var(--letter-spacing-spacious);
  font-size: calc(16rem / 16);
  line-height: calc(24rem / 16);
  color: var(--text-bright);
  cursor: text;
  padding-left: 6px;

  position: absolute;
  left: 0;
  top: 20px;

  -webkit-transition: -webkit-transform var(--rapid);

  transition: -webkit-transform var(--rapid);

  transition: transform var(--rapid);

  transition: transform var(--rapid), -webkit-transform var(--rapid);
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
}

.Form_labelLine__3jpM1 {
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--stroke);
  position: relative;
}

.Form_labelLine__3jpM1::before,
.Form_labelLine__3jpM1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transition: -webkit-transform var(--rapid) var(--rapid);
  transition: -webkit-transform var(--rapid) var(--rapid);
  transition: transform var(--rapid) var(--rapid);
  transition: transform var(--rapid) var(--rapid), -webkit-transform var(--rapid) var(--rapid);
}

.Form_labelLine__3jpM1::before {
  background-color: var(--primary-color);
}

.Form_labelLine__3jpM1::after {
  background-color: red;
}

.Form_input__2v4BS:focus + .Form_label__1H3hN .Form_labelLine__3jpM1::before,
.Form_select__tPhnL:focus + .Form_label__1H3hN .Form_labelLine__3jpM1::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  will-change: transform;
}

.Form_input__2v4BS:focus + .Form_label__1H3hN .Form_labelText__2NJYk,
.Form_input__2v4BS:not(:placeholder-shown) + .Form_label__1H3hN .Form_labelText__2NJYk,
.Form_select__tPhnL + .Form_label__1H3hN .Form_labelText__2NJYk {
  -webkit-transform: translateY(-100%) scale(0.875);
          transform: translateY(-100%) scale(0.875);
}

/* If input field has not been filled out */
.Form_input__2v4BS:placeholder-shown + .Form_label__1H3hN .Form_labelText__2NJYk,
.Form_input__2v4BS:placeholder-shown + .Form_label__1H3hN .Form_labelLine__3jpM1 {
  will-change: transform;
}

/* Errors */

.Form_error__4ppTu {
  visibility: hidden;
  color: red;
  font-size: calc(12rem / 16);
  line-height: calc(16rem / 16);
  min-height: calc(2 * (16rem / 16));
  width: 100%;
  margin-left: 11px;
}

.Form_error__4ppTu[data-show-error="true"] {
  visibility: visible;
}

.Form_input__2v4BS:invalid {
  /* Reset default styling */
  box-shadow: none;
  /* Reset default styling - END */
}

.Form_input__2v4BS:invalid + .Form_label__1H3hN .Form_labelLine__3jpM1::after,
.Form_select__tPhnL:invalid + .Form_label__1H3hN .Form_labelLine__3jpM1::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  will-change: transform;
}

.SlideIn_close__3vGj- {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.SlideIn_closeIcon__20SYQ {
  display: block;
  width: 100%;
}

.SlideIn_box__1MZT9 {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: var(--text);
  color: white;
  padding: var(--spacing);
  z-index: 10;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

  -webkit-transform: translateY(100%);

          transform: translateY(100%);
  -webkit-transition: -webkit-transform var(--fast);
  transition: -webkit-transform var(--fast);
  transition: transform var(--fast);
  transition: transform var(--fast), -webkit-transform var(--fast);
}

.SlideIn_slideIn__2d6yF[data-visible="true"] .SlideIn_box__1MZT9 {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.SlideIn_slideIn__2d6yF .SlideIn_form__3BQNq {
  margin-top: 20px;
  margin-bottom: 20px;
}

.SlideIn_slideIn__2d6yF .SlideIn_form__input__21ssT,
.SlideIn_slideIn__2d6yF .SlideIn_form__label-text__2Llsl {
  color: white;
}

.SlideIn_placeholder__3qnp0 {
  padding: var(--spacing);
  display: none;
}

.SlideIn_slideIn__2d6yF[data-visible="true"] .SlideIn_placeholder__3qnp0 {
  display: block;
}

.BeverageFact_list__3sA-B {
  width: 100%;
  padding-left: 0;
  margin-top: 50px;
  list-style: none;
  text-align: left;

  max-width: var(--max-size);
  margin-right: auto;
  margin-left: auto;
}

.BeverageFact_item__3KaV- {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.BeverageFact_item__3KaV- + .BeverageFact_item__3KaV- {
  border-top: 1px solid var(--stroke);
}

.BeverageFact_icon__2lPsg {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  display: block;
  width: 45px;
  height: 45px;
  object-fit: contain;
  margin-right: 20px;
  flex-grow: 0;
}

.BeverageFact_text__2h1wl {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.BeverageFact_term__6XS12 {
  font-size: calc(12rem / 16);
  line-height: calc(16rem / 16);
}

.BeverageFact_description__3v0Vw {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  margin-top: 5px;
}

.BeverageFact_item__3KaV-[appearance="big"] {
  min-width: 400px;
  padding-right: 20px;
}

.BeverageFact_item__3KaV-[appearance="big"] .BeverageFact_icon__2lPsg {
  width: 75px;
  height: 75px;
}

.BeverageFact_item__3KaV-[appearance="big"] .BeverageFact_term__6XS12 {
  font-size: calc(18rem / 16);
  line-height: calc(30rem / 16);
}

.BeverageFact_item__3KaV-[appearance="big"] .BeverageFact_description__3v0Vw {
  font-size: calc(18rem / 16);
  line-height: calc(24rem / 16);
}

/* TODO: This has no matching component, it is styling for a higher order component, is that ok? */
.Beverage_beverage__LbZkl {
  --image-height: 250px;
}

.Beverage_beverage__content__EA-1R {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform var(--fast);
  transition: -webkit-transform var(--fast);
  transition: transform var(--fast);
  transition: transform var(--fast), -webkit-transform var(--fast);
  text-align: center;
}

[data-zoom-bottle="true"] + .Beverage_beverage__content__EA-1R {
  -webkit-transform: translateY(var(--image-height));
          transform: translateY(var(--image-height));
}

/* Media query */

@media screen and (min-height: 1024px) {
  .Beverage_beverage__LbZkl {
    --image-height: 400px;
  }
}

.Avatar_avatar__3Z8M5 {
  width: 100%;
  position: relative;
}

.Avatar_avatar__3Z8M5::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.Avatar_image__xaWmD {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  object-fit: cover;

  background: url(/static/media/profile--blue.eaebb79d.svg) no-repeat 50% /
    contain;
  color: rgba(0, 0, 0, 0);
}

.ProfileBrief_profile__3859a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProfileBrief_avatar__15N4N {
  width: 50%;
  max-width: 200px;
  margin-bottom: 20px;
}

.ProfileBrief_name__2ZgrV {
  font-weight: 600;
  letter-spacing: var(--letter-spacing-spacious);
}

.StepList_stepList__NlZAD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.StepList_stepList__item__2_KPa {
  font-size: calc(26rem / 16);
  line-height: calc(30rem / 16);
  text-align: center;

  margin-top: 25px;
  margin-bottom: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.StepList_stepList__counter__978uK {
  --size: 40px;
  --border: 2px;
  width: var(--size);
  height: var(--size);
  line-height: calc(var(--size) - (2 * var(--border)));
  border-radius: 50%;
  border: var(--border) solid white;
}

.StepList_stepList__number__2shcX {
}

.StepList_stepList__text__3Qpwr {
  margin-top: 10px;
}

.NumberedList_list__3snx9 {
  padding-left: 0;
  list-style: none;
}

.NumberedList_item__3Q6Nz {
  font-size: calc(24rem / 16);
  line-height: calc(30rem / 16);
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.NumberedList_column__OlbSo {
  display: flex;
  flex-direction: column;
}

.NumberedList_row__uzviH {
  display: flex;
}

.NumberedList_count__3WRYe {
  font-size: calc(24rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 600;

  width: 50px;
  display: inline-block;
  text-align: right;
  margin-right: 20px;
}

.NumberedList_name__29cAw {
  width: 300px;
  display: inline-block;
  margin-right: 20px;
}

.NumberedList_row__uzviH > .NumberedList_row__uzviH .NumberedList_name__29cAw {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
}

.NumberedList_difference__3-Lzi {
  width: 130px;
  display: inline-block;
  text-align: right;
}

.NumberedList_guesses__3GS_q {
  font-size: calc(16rem / 16);
  line-height: calc(24rem / 16);
}

.Dashboard_dashboard__3jCZu {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;

  height: 100%;
  text-align: center;
  position: relative;

  /* border-right: var(--stroke-width) solid var(--stroke); */
  background-color: var(--presentation-stroke);
}

.Dashboard_module__DqlR9 {
  position: relative;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--text);
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  -webkit-transition: background-color var(--fast);

  transition: background-color var(--fast);
}

.Dashboard_module__DqlR9:not(:first-of-type)::before {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  background-color: var(--stroke-dark);
  top: 0px;
  left: 0;
  width: 70%;
  right: 0;
  margin: auto;
}

.Dashboard_module__DqlR9[active="true"]::before,
.Dashboard_module__DqlR9[active="true"] + .Dashboard_module__DqlR9::before {
  display: none;
}

.Dashboard_module__DqlR9[active="true"] {
  background-color: white;
}

.Dashboard_image__2hPiX {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  width: 100%;
  height: 70px;
  display: block;
  margin-bottom: 10px;
  object-fit: contain;
}

.Dashboard_text__1UwH3 {
  font-size: calc(26rem / 16);
  line-height: calc(40rem / 16);
  font-weight: 600;
  display: block;
}

.Dashboard_unit__3jg2K {
  font-size: calc(12rem / 16);
  line-height: calc(20rem / 16);
  text-transform: uppercase;
  display: block;
  letter-spacing: var(--letter-spacing-spacious);
}

.Marquee_marquee__2JYvx {
  --marquee-duration: 30s;
  --marquee-delay: 2s;

  border-top: 1px solid var(--stroke);
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;

  -webkit-transform: translateY(100%);

          transform: translateY(100%);
}

.Marquee_marquee__2JYvx[active="true"] {
  -webkit-animation: calc(var(--marquee-duration) + (var(--marquee-delay) * 2)) linear 1
    both running :local(showHidemarquee);
          animation: calc(var(--marquee-duration) + (var(--marquee-delay) * 2)) linear 1
    both running :local(showHidemarquee);
}

.Marquee_content__3Y11d {
  /* background-color: yellow; */
  display: inline-block;

  height: var(--marquee-height);
  position: absolute;
  left: 100%;
}

.Marquee_marquee__2JYvx[active="true"] .Marquee_content__3Y11d {
  will-change: transform;
  -webkit-animation: var(--marquee-duration) linear 2s 1 forwards running :local(marquee);
          animation: var(--marquee-duration) linear 2s 1 forwards running :local(marquee);
}

@-webkit-keyframes Marquee_showHidemarquee__3EJAi {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  1% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  99% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@keyframes Marquee_showHidemarquee__3EJAi {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  1% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  99% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@-webkit-keyframes Marquee_marquee__2JYvx {
  0% {
    -webkit-transform: translateX(-0%);
            transform: translateX(-0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes Marquee_marquee__2JYvx {
  0% {
    -webkit-transform: translateX(-0%);
            transform: translateX(-0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.Ranking_list__1rZcy {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
}

.Ranking_list__1rZcy::before {
  content: "Accumulated score: ";
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  line-height: var(--marquee-height);

  -webkit-transform: translateX(-100%);

          transform: translateX(-100%);
}

.Ranking_item__6HBGs {
  display: inline-block;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  line-height: var(--marquee-height);
  position: relative;
}

.Ranking_item__6HBGs:nth-of-type(1),
.Ranking_item__6HBGs:nth-of-type(2),
.Ranking_item__6HBGs:nth-of-type(3) {
  background-color: rgba(0, 255, 0, 0.5);
}

.Ranking_item__6HBGs:nth-last-of-type(1),
.Ranking_item__6HBGs:nth-last-of-type(2),
.Ranking_item__6HBGs:nth-last-of-type(3) {
  background-color: rgba(255, 0, 0, 0.5);
}

.Ranking_position__2x4FI {
  font-weight: 600;
  margin-right: 10px;
  background-color: var(--presentation-stroke);
  display: inline-block;
  text-align: center;
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.Ranking_name__3JNsV {
  font-weight: 600;
  margin-right: 10px;
}

.Ranking_name__3JNsV::after {
  content: ":";
}

.Ranking_difference__3MaFU {
}

:root {
  --primary-color: #387c6a;
  --primary-color--darker: #226b5e;

  --text: #414255;
  --text-bright: #787987;

  --stroke: #e9edf0;
  --stroke-dark: var(--text);

  --highlight: #e9edf0;

  /* --background: #f9f7f6; */
  --background: #ffffff;

  /* Sizes */
  --max-size: 800px;

  /* Spacing */
  --spacing: 20px;
  --stroke-width: 1px;

  /* Animations */
  --rapid: 0.1s;
  --fast: 0.3s;
  --slow: 0.5s;

  /* Typography */
  --font-family-sans-serif: "semplicitapro", Arial, sans-serif;
  --font-family-serif: "Cormorant", Georgia, serif;
  --font-family-display: "semplicitapro", Arial, sans-serif;

  /* Letter spacing */
  --letter-spacing-tight: 0.025em;
  --letter-spacing-spacious: 0.075em;

  /* Presentation */
  --presentation-stroke: #cccccc;
}

@font-face {
  font-family: "Cormorant";
  src: url(/static/media/cormorant-medium-webfont.ecd062c9.woff2);
  font-weight: 500;
}

@font-face {
  font-family: "Cormorant";
  src: url(/static/media/cormorant-mediumitalic-webfont.25473e31.woff2);
  font-weight: 500;
  font-style: italic;
}



*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: var(--font-family-sans-serif);
  background: var(--background);
  margin: 0;
  color: var(--text);
  letter-spacing: var(--letter-spacing-tight);
}

.wrapper {
  padding: var(--spacing);
  min-height: 100vh;
  position: relative;
}

.wrapper[fullheight="true"] {
  height: 100vh;
  padding: 0;
}

.wrapper[fullheight="true"] .transition-wrapper {
  height: 100%;
}

.wrapper[fullheight="true"] .transition-element {
  height: 100%;
}

.comma-separated-list:not(:last-of-type)::after {
  content: ", ";
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  -webkit-transition: -webkit-transform var(--fast);
  transition: -webkit-transform var(--fast);
  transition: transform var(--fast);
  transition: transform var(--fast), -webkit-transform var(--fast);
}

.close:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: -webkit-transform var(--rapid);
  transition: -webkit-transform var(--rapid);
  transition: transform var(--rapid);
  transition: transform var(--rapid), -webkit-transform var(--rapid);
}

.close__icon {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  height: 40px;
  width: 40px;
  display: block;
}

.onboarding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  padding: 20px;

  background: url(/static/media/onboarding.f9f30a02.jpg) no-repeat 50% / cover;
  color: white;
}

.host {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  text-align: center;
}

.host__title {
  font-size: calc(40rem / 16);
  line-height: calc(50rem / 16);
  margin-top: 0;
  margin-bottom: 0;
}

.host__name {
  font-family: var(--font-family-serif);
  font-size: calc(26rem / 16);
  line-height: calc(36rem / 16);
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
}

.host__abstract {
  font-size: calc(22rem / 16);
  line-height: calc(30rem / 16);
  margin-top: 50px;
  margin-bottom: 0;
  width: 70%;
}

.presentation-beverage {
  --stroke: var(--presentation-stroke);
  --marquee-height: 50px;

  display: grid;
  grid-template-columns: 1fr 12fr;
  /* grid-template-rows: auto var(--marquee-height); */
  grid-gap: 0;
  grid-auto-flow: dense;

  height: 100vh;
}

.presentation-beverage__dashboard {
  height: 100%;
}

.presentation-beverage__wrapper {
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.presentation-beverage__marquee {
  /* grid-column: span 2; */
  position: fixed;
  width: 100%;
  height: var(--marquee-height);
  bottom: 0;
}

.presentation-beverage__heading {
  font-size: calc(36rem / 16);
  line-height: calc(50rem / 16);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: var(--letter-spacing-spacious);
  margin-top: 10px;
  margin-bottom: 30px;
}

.presentation-beverage__content {
  height: calc(100% - 20px - 50px - 20px - 50px);
  padding-top: 50px;
}

.presentation {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.presentation__bottle {
  width: 30%;
  height: 80%;
  display: flex;
  justify-content: flex-end;

  /* padding-right: 20px; */
  position: relative;
}

.presentation__image {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;

  position: absolute;
}

.presentation__facts {
  list-style: none;
  padding-left: 0;
}

.presentation__facts:not(:first-of-type) {
  padding-left: 40px;
}

/* .presentation__fact {
  font-size: calc(30rem / 16);
  line-height: calc(40rem / 16);
  font-weight: 600;

  margin-top: 10px;
  margin-bottom: 10px;
} */

.presentation-rating {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
}

.priceGuess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.priceGuess__price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 500;
  margin-bottom: 40px;
}

.priceGuess__tag {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
}

.priceGuess__price-result {
  font-size: calc(40rem / 16);
  line-height: calc(40rem / 16);
  font-weight: 600;
}

.priceGuess__list {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 500;
  margin-bottom: 20px;
}

/* @import "./header.css"; */
/* @import "./login.css"; */
/* @import "./tastings-list.css"; */
/* @import "./tastings-item.css"; */
/* @import "./profile.css"; */
/* @import "./avatar.css"; */
/* @import "./spinner.css"; */
/* @import "./error.css"; */
/* @import "./beverages-list.css"; */
/* @import "./beverages-item.css"; */
/* @import "./beverage.css"; */
/* @import "./rating.css"; */
/* @import "./quiz.css"; */
/* @import "./divider.css"; */
/* @import "./beverage-fact.css"; */
/* @import "./presentation/dashboard.css"; */
/* @import "./heading.css"; */
/* @import "./marquee.css"; */
/* @import "./ranking-list.css"; */

/* Settings */

/* Generic */

/* Components */

.TastingsItem_tastingsItem__4UQOG + .TastingsItem_tastingsItem__4UQOG {
  border-top: 1px solid var(--stroke);
}

.TastingsItem_link__11rxc {
  text-decoration: none;
  color: var(--text);

  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  padding-top: 20px;
  padding-bottom: 20px;

  -webkit-transition: background-color var(--fast);

  transition: background-color var(--fast);
}

.TastingsItem_link__11rxc:hover {
  background-color: var(--highlight);

  -webkit-transition: background-color var(--rapid);

  transition: background-color var(--rapid);
}

.TastingsItem_typeImage__2pN3E {
  width: 60px;
  height: 60px;
  object-fit: contain;
  flex-shrink: 0;
  flex-grow: 0;
  padding-right: 10px;
}

.TastingsItem_info__5I4V4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.TastingsItem_date__2YPYe {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  margin-bottom: 5px;
}

.TastingsItem_name__2x49L {
  font-size: calc(16rem / 16);
  line-height: calc(20rem / 16);
  color: var(--primary-color);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  margin-top: 0;
  margin-bottom: 0;
}

.TastingsItem_topic__3HPl7 {
  font-size: calc(14rem / 16);
  line-height: calc(20rem / 16);
  margin-top: 4px;
  margin-bottom: 0;
}

.TastingsItem_cta__1ZlMY {
  margin-right: 10px;
  flex-shrink: 0;
  flex-grow: 0;
}

.TastingsList_tastingsList__1tkmn {
  margin-top: 30px;

  max-width: var(--max-size);
  margin-right: auto;
  margin-left: auto;
}

.TastingsList_yearList__zO3Vg {
  list-style: none;
  padding: 0;
}

.TastingsList_yearList__zO3Vg:last-of-type {
  margin-bottom: 0;
}

