.button {
  --button-color: white;
  --button-background: var(--primary-color);
  --button-background-hover: var(--primary-color--darker);
  --button-background-disabled: var(--text-bright);

  background-color: var(--button-background);
  color: var(--button-color);
  border: none;
  font-size: calc(14rem / 16);
  line-height: calc(18rem / 16);
  padding: 16px 50px;
  border-radius: 100px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: var(--letter-spacing-spacious);
  font-family: var(--font-family-display);
  display: inline-block;

  transition: transform var(--fast), background-color var(--slow);
}

.button:not([priority="secondary"]) + .button {
  margin-top: 10px;
}

.button[appearance="big"] {
  font-size: calc(18rem / 16);
  line-height: calc(24rem / 16);
}

.button[priority="secondary"] {
  background-color: transparent;
  color: var(--text);
  font-size: calc(14rem / 16);
  line-height: calc(18rem / 16);
  border-radius: 0;
  text-decoration: underline;
  text-transform: none;
  padding: 8px 20px;
}

.button:hover {
  transform: scale(1.05);
  transition: transform var(--rapid), background-color var(--rapid);
}

.button[disabled]:hover,
.button[priority="secondary"]:hover {
  transform: none;
}

.button:not([priority="secondary"]):hover,
.button:not([priority="secondary"]):focus {
  background-color: var(--button-background-hover);
  outline: none;
}

.button::-moz-focus-inner {
  border: 0;
}

.button:not([priority="secondary"]):disabled {
  background-color: var(--button-background-disabled);
  cursor: default;
}

.button[priority="secondary"]:disabled {
  color: var(--stroke);
  cursor: default;
}
