.avatar {
  width: 100%;
  position: relative;
}

.avatar::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  object-fit: cover;

  background: url(../../../images/icons/profile--blue.svg) no-repeat 50% /
    contain;
  color: rgba(0, 0, 0, 0);
}
