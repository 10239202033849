@import url("https://use.typekit.net/xws4exx.css");

@font-face {
  font-family: "Cormorant";
  src: url(../../fonts/cormorant-medium-webfont.woff2);
  font-weight: 500;
}

@font-face {
  font-family: "Cormorant";
  src: url(../../fonts/cormorant-mediumitalic-webfont.woff2);
  font-weight: 500;
  font-style: italic;
}
