.error {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.text {
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.details {
  font-size: calc(12rem / 16);
  line-height: calc(20rem / 16);
}

.appear {
  opacity: 0.01;
}

.appear.appearActive {
  opacity: 1;
  transition: opacity 0.25s 0.25s ease-in;
}
