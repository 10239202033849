.paragraph {
  font-size: calc(16rem / 16);
  line-height: calc(30rem / 16);
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 50px;

  max-width: var(--max-size);
  margin-right: auto;
  margin-left: auto;
}

.paragraph + .paragraph {
  margin-top: 20px;
}

.paragraph[align="left"] {
  text-align: left;
  padding-right: 0;
  padding-left: 0;
}
