.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 50%;
  max-width: 200px;
  margin-bottom: 20px;
}

.name {
  font-weight: 600;
  letter-spacing: var(--letter-spacing-spacious);
}
