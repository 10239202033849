.bottle {
  background-color: white;
  margin-right: calc(var(--spacing) * -1);
  margin-left: calc(var(--spacing) * -1);
  position: relative;
  height: var(--image-height);
  cursor: pointer;
}

.bottleImage {
  display: block;
  width: 50%;
  height: 100%;
  object-fit: contain;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  transform: scale(1);
  transform-origin: top;
  transition: transform var(--fast);
}

.bottle[data-zoom-bottle="true"] .bottleImage {
  transform: scale(2);
}

.vines {
  width: 100%;
  height: calc(100% + 100px);
  position: absolute;
  overflow: hidden;
}

.vine {
  position: absolute;
  right: -250px;
  bottom: 120px;
  width: 200px;
  z-index: 1;
  transform: rotate(105deg);
  transform-origin: 0% 0%;
  transition: transform var(--slow);
}

.vine2 {
  position: absolute;
  left: -230px;
  bottom: 160px;
  width: 200px;
  z-index: 1;
  transform: rotate(-100deg);
  transform-origin: 100% 0%;
  transition: transform var(--slow);
}

.bottle[data-zoom-bottle="true"] .vine {
  transform: rotate(90deg);
}

.bottle[data-zoom-bottle="true"] .vine2 {
  transform: rotate(-80deg);
}

/* Animation */

.vineAppear .vine {
  transform: rotate(90deg);
}

.vineAppear .vine2 {
  transform: rotate(-80deg);
}
