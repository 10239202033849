*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: var(--font-family-sans-serif);
  background: var(--background);
  margin: 0;
  color: var(--text);
  letter-spacing: var(--letter-spacing-tight);
}

.wrapper {
  padding: var(--spacing);
  min-height: 100vh;
  position: relative;
}

.wrapper[fullheight="true"] {
  height: 100vh;
  padding: 0;
}

.wrapper[fullheight="true"] .transition-wrapper {
  height: 100%;
}

.wrapper[fullheight="true"] .transition-element {
  height: 100%;
}

.comma-separated-list:not(:last-of-type)::after {
  content: ", ";
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  transition: transform var(--fast);
}

.close:hover {
  transform: scale(1.2);
  transition: transform var(--rapid);
}

.close__icon {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  height: 40px;
  width: 40px;
  display: block;
}
