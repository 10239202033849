.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(var(--spacing) * -1);
  margin-right: calc(var(--spacing) * -1);
  margin-left: calc(var(--spacing) * -1);
  padding: 20px;
}

.link {
  min-height: 30px;
  padding: 10px;
  margin: -10px;
  display: block;
  cursor: pointer;

  transition: transform var(--fast);
}

.link:hover {
  transform: scale(1.2);
  transition: transform var(--rapid);
}

.icon {
  width: 30px;
  height: 30px;
  display: block;
  object-fit: contain;
}

.iconArrow {
  width: 25px;
}
