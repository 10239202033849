.presentation {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.presentation__bottle {
  width: 30%;
  height: 80%;
  display: flex;
  justify-content: flex-end;

  /* padding-right: 20px; */
  position: relative;
}

.presentation__image {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;

  position: absolute;
}

.presentation__facts {
  list-style: none;
  padding-left: 0;
}

.presentation__facts:not(:first-of-type) {
  padding-left: 40px;
}

/* .presentation__fact {
  font-size: calc(30rem / 16);
  line-height: calc(40rem / 16);
  font-weight: 600;

  margin-top: 10px;
  margin-bottom: 10px;
} */
