.tastingsList {
  margin-top: 30px;

  max-width: var(--max-size);
  margin-right: auto;
  margin-left: auto;
}

.yearList {
  list-style: none;
  padding: 0;
}

.yearList:last-of-type {
  margin-bottom: 0;
}
