.list {
  width: 100%;
  padding-left: 0;
  margin-top: 50px;
  list-style: none;
  text-align: left;

  max-width: var(--max-size);
  margin-right: auto;
  margin-left: auto;
}

.item {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.item + .item {
  border-top: 1px solid var(--stroke);
}

.icon {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  display: block;
  width: 45px;
  height: 45px;
  object-fit: contain;
  margin-right: 20px;
  flex-grow: 0;
}

.text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.term {
  font-size: calc(12rem / 16);
  line-height: calc(16rem / 16);
}

.description {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  margin-top: 5px;
}

.item[appearance="big"] {
  min-width: 400px;
  padding-right: 20px;
}

.item[appearance="big"] .icon {
  width: 75px;
  height: 75px;
}

.item[appearance="big"] .term {
  font-size: calc(18rem / 16);
  line-height: calc(30rem / 16);
}

.item[appearance="big"] .description {
  font-size: calc(18rem / 16);
  line-height: calc(24rem / 16);
}
