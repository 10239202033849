.presentation-beverage {
  --stroke: var(--presentation-stroke);
  --marquee-height: 50px;

  display: grid;
  grid-template-columns: 1fr 12fr;
  /* grid-template-rows: auto var(--marquee-height); */
  grid-gap: 0;
  grid-auto-flow: dense;

  height: 100vh;
}

.presentation-beverage__dashboard {
  height: 100%;
}

.presentation-beverage__wrapper {
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.presentation-beverage__marquee {
  /* grid-column: span 2; */
  position: fixed;
  width: 100%;
  height: var(--marquee-height);
  bottom: 0;
}

.presentation-beverage__heading {
  font-size: calc(36rem / 16);
  line-height: calc(50rem / 16);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: var(--letter-spacing-spacious);
  margin-top: 10px;
  margin-bottom: 30px;
}

.presentation-beverage__content {
  height: calc(100% - 20px - 50px - 20px - 50px);
  padding-top: 50px;
}
