.beveragesItem + .beveragesItem {
  border-top: 1px solid var(--stroke);
}

.link {
  color: var(--text);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  transition: background-color var(--fast);
}

.link:hover {
  background-color: var(--highlight);

  transition: background-color var(--rapid);
}

.bottle {
  display: block;
  width: 60px;
  height: 120px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  object-fit: contain;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Min-width should match var(--max-size) */
@media screen and (min-width: 1024px) {
  .emptySpace {
    width: 60px;
    height: 120px;

    flex-grow: 0;
    flex-shrink: 0;
  }
}
