.tastingsItem + .tastingsItem {
  border-top: 1px solid var(--stroke);
}

.link {
  text-decoration: none;
  color: var(--text);

  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  padding-top: 20px;
  padding-bottom: 20px;

  transition: background-color var(--fast);
}

.link:hover {
  background-color: var(--highlight);

  transition: background-color var(--rapid);
}

.typeImage {
  width: 60px;
  height: 60px;
  object-fit: contain;
  flex-shrink: 0;
  flex-grow: 0;
  padding-right: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.date {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  margin-bottom: 5px;
}

.name {
  font-size: calc(16rem / 16);
  line-height: calc(20rem / 16);
  color: var(--primary-color);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  margin-top: 0;
  margin-bottom: 0;
}

.topic {
  font-size: calc(14rem / 16);
  line-height: calc(20rem / 16);
  margin-top: 4px;
  margin-bottom: 0;
}

.cta {
  margin-right: 10px;
  flex-shrink: 0;
  flex-grow: 0;
}
