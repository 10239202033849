.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;

  height: 100%;
  text-align: center;
  position: relative;

  /* border-right: var(--stroke-width) solid var(--stroke); */
  background-color: var(--presentation-stroke);
}

.module {
  position: relative;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--text);
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: background-color var(--fast);
}

.module:not(:first-of-type)::before {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  background-color: var(--stroke-dark);
  top: 0px;
  left: 0;
  width: 70%;
  right: 0;
  margin: auto;
}

.module[active="true"]::before,
.module[active="true"] + .module::before {
  display: none;
}

.module[active="true"] {
  background-color: white;
}

.image {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  width: 100%;
  height: 70px;
  display: block;
  margin-bottom: 10px;
  object-fit: contain;
}

.text {
  font-size: calc(26rem / 16);
  line-height: calc(40rem / 16);
  font-weight: 600;
  display: block;
}

.unit {
  font-size: calc(12rem / 16);
  line-height: calc(20rem / 16);
  text-transform: uppercase;
  display: block;
  letter-spacing: var(--letter-spacing-spacious);
}
