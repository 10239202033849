.host {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  text-align: center;
}

.host__title {
  font-size: calc(40rem / 16);
  line-height: calc(50rem / 16);
  margin-top: 0;
  margin-bottom: 0;
}

.host__name {
  font-family: var(--font-family-serif);
  font-size: calc(26rem / 16);
  line-height: calc(36rem / 16);
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 0;
}

.host__abstract {
  font-size: calc(22rem / 16);
  line-height: calc(30rem / 16);
  margin-top: 50px;
  margin-bottom: 0;
  width: 70%;
}
