.list {
  padding-left: 0;
  list-style: none;
}

.item {
  font-size: calc(24rem / 16);
  line-height: calc(30rem / 16);
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.count {
  font-size: calc(24rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 600;

  width: 50px;
  display: inline-block;
  text-align: right;
  margin-right: 20px;
}

.name {
  width: 300px;
  display: inline-block;
  margin-right: 20px;
}

.row > .row .name {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
}

.difference {
  width: 130px;
  display: inline-block;
  text-align: right;
}

.guesses {
  font-size: calc(16rem / 16);
  line-height: calc(24rem / 16);
}
