.onboarding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  padding: 20px;

  background: url("./../../images/mood/onboarding.jpg") no-repeat 50% / cover;
  color: white;
}
