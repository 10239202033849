/* TODO: This has no matching component, it is styling for a higher order component, is that ok? */
.beverage {
  --image-height: 250px;
}

.beverage__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  transform: translateY(0);
  transition: transform var(--fast);
  text-align: center;
}

[data-zoom-bottle="true"] + .beverage__content {
  transform: translateY(var(--image-height));
}

/* Media query */

@media screen and (min-height: 1024px) {
  .beverage {
    --image-height: 400px;
  }
}
