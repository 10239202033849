.priceGuess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.priceGuess__price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 500;
  margin-bottom: 40px;
}

.priceGuess__tag {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
}

.priceGuess__price-result {
  font-size: calc(40rem / 16);
  line-height: calc(40rem / 16);
  font-weight: 600;
}

.priceGuess__list {
  font-size: calc(20rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 500;
  margin-bottom: 20px;
}
