.text {
  padding-right: 20px;
  padding-left: 20px;
}

.appear {
  opacity: 0.01;
}

.appear.appearActive {
  opacity: 1;
  transition: opacity 0.25s 1s ease-in;
}
