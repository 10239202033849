.rating {
}

.rating[is-locked="true"] {
  pointer-events: none;
}

.text {
  font-size: calc(14rem / 16);
  line-height: calc(16rem / 16);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-spacious);
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.ratings {
  display: flex;
  justify-content: center;
  align-items: center;
}

.units {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: left;
  display: flex;
}

.unit {
  display: block;
  position: relative;
  width: 1.1em;
  cursor: pointer;
  background: url("../../../images/icons/heart.svg") no-repeat center / contain;
  height: 25px;
  width: 25px;
  padding-right: 6px;
  padding-left: 6px;
  box-sizing: content-box;
}

.unit::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../../images/icons/heart-filled.svg") no-repeat center /
    contain;
  opacity: 0;

  transition: opacity var(--rapid);
}

.units[data-simple="true"] {
  margin-top: 10px;
}

.units[data-simple="true"] .unit {
  height: 15px;
  width: 15px;
  padding-right: 2px;
  padding-left: 2px;
  background-image: url("../../../images/icons/heart--blue.svg");
}

.rating[is-locked="true"] .unit {
  background-image: url("../../../images/icons/heart--blue.svg");
}

.units[data-simple="true"] .unit::after,
.rating[is-locked="true"] .unit::after {
  background-image: url("../../../images/icons/heart-filled--blue.svg");
}

/* Only show rated hearts when mouse is not hovering */
.units:not(:hover) .unit[rated="true"]::after,
.units:not(:hover) .unit[rated="true"] ~ .unit::after,

/* Always show rated hearts on a simple component */
.units[data-simple="true"] .unit[rated="true"]::after,
.units[data-simple="true"] .unit[rated="true"] ~ .unit::after,

/* Only show hovered hearts on a not simple component */
.units:not([data-simple="true"]) .unit:hover::after,
.units:not([data-simple="true"]) .unit:hover ~ .unit::after {
  opacity: 1;
}

.average {
  font-size: calc(12rem / 16);
  line-height: calc(16rem / 16);
  display: block;
  margin-top: 0;
  margin-left: 4px;
  margin-bottom: 0;
}

.units[data-simple="true"] + .average {
  margin-top: 10px;
}
