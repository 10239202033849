.landing {
  height: 100vh;
  min-height: 640px;
  position: relative;
  overflow: hidden;
}

.logo {
  width: 200px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.backgroundColor {
  /* background-color: #f0f1f3; */
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.1);
  margin: calc(var(--spacing) / 2);
  height: calc(100% - (var(--spacing) / 2) * 2);
  width: calc(100% - (var(--spacing) / 2) * 2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vineBottomRight {
  position: absolute;
  right: -240px;
  bottom: -10px;
  width: 200px;
  transform: rotate(120deg);
  transform-origin: 0% 0%;
}

.vineTopLeft {
  position: absolute;
  left: -56px;
  top: -40px;
  width: 200px;
  transform: rotate(337deg);
  transform-origin: 100% 0%;
}

/* Animation */

.vineBottomRightAppear {
  transform: rotate(80deg);
}

.vineBottomRightAppear.vineBottomRightAppearActive {
  transform: rotate(120deg);
  transition: transform var(--slow) var(--fast);
}

.vineTopLeftAppear {
  transform: rotate(377deg);
}

.vineTopLeftAppear.vineTopLeftAppearActive {
  transform: rotate(337deg);
  transition: transform var(--slow) var(--fast);
}
