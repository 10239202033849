.beverageBrief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.type {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  display: block;
  object-fit: contain;
  width: 40px;
  height: 40px;
}

.heading {
  font-size: calc(24rem / 16);
  line-height: calc(30rem / 16);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-spacious);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.subHeading {
  font-family: var(--font-family-serif);
  font-style: italic;
  font-size: calc(20rem / 16);
  line-height: calc(24rem / 16);
  margin-top: 0;
  margin-bottom: 0;
}

.beverageBrief[appearance="small"] {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}

[appearance="small"] .type {
  margin-bottom: 5px;
}

[appearance="small"] .heading {
  color: var(--primary-color);
  font-size: calc(16rem / 16);
  line-height: calc(20rem / 16);
  margin-top: 6px;
  margin-bottom: 6px;
}

[appearance="small"] .subHeading {
  font-family: var(--font-family-serif);
  font-size: calc(16rem / 16);
  line-height: calc(20rem / 16);
  margin-top: 4px;
}
