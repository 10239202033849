.divider {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.ruler {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: calc(2 * var(--stroke-width));
  border: none;
  background-color: var(--stroke);
  margin-top: auto;
  margin-bottom: auto;
}

.divider[unobtrusive="true"] {
  margin-top: -10px;
  margin-bottom: -10px;
}

.divider[appearance="faint"] .ruler {
  height: var(--stroke-width);
  background-color: var(--stroke);
}

.divider[appearance="thin"] .ruler {
  height: var(--stroke-width);
}

.divider[narrow="true"] {
  max-width: var(--max-size);
  margin-right: auto;
  margin-left: auto;
}

.divider[spacing="spacious"] {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mask {
  text-align: center;
  position: relative;
  background-color: var(--background);
  padding-right: 20px;
  padding-left: 20px;
}

.image {
  font-size: calc(12rem / 16);
  line-height: calc(12rem / 16);
  display: block;
  width: 50px;
  height: 50px;
}

.divider[appearance="thin"] .image {
  width: 40px;
  height: 40px;
}

.text {
  font-size: calc(16rem / 16);
  line-height: calc(22rem / 16);
  font-weight: 600;
  display: block;
}

.divider[presentation-mode="true"] {
  margin-top: 0px;
}

[presentation-mode="true"] .image {
  width: 80px;
  height: 80px;
}

[presentation-mode="true"] .ruler {
  height: 1px;
  background-color: #ccc;
}
