.stepList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stepList__item {
  font-size: calc(26rem / 16);
  line-height: calc(30rem / 16);
  text-align: center;

  margin-top: 25px;
  margin-bottom: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepList__counter {
  --size: 40px;
  --border: 2px;
  width: var(--size);
  height: var(--size);
  line-height: calc(var(--size) - (2 * var(--border)));
  border-radius: 50%;
  border: var(--border) solid white;
}

.stepList__number {
}

.stepList__text {
  margin-top: 10px;
}
