.list {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
}

.list::before {
  content: "Accumulated score: ";
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  line-height: var(--marquee-height);

  transform: translateX(-100%);
}

.item {
  display: inline-block;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  line-height: var(--marquee-height);
  position: relative;
}

.item:nth-of-type(1),
.item:nth-of-type(2),
.item:nth-of-type(3) {
  background-color: rgba(0, 255, 0, 0.5);
}

.item:nth-last-of-type(1),
.item:nth-last-of-type(2),
.item:nth-last-of-type(3) {
  background-color: rgba(255, 0, 0, 0.5);
}

.position {
  font-weight: 600;
  margin-right: 10px;
  background-color: var(--presentation-stroke);
  display: inline-block;
  text-align: center;
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.name {
  font-weight: 600;
  margin-right: 10px;
}

.name::after {
  content: ":";
}

.difference {
}
