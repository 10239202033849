.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.closeIcon {
  display: block;
  width: 100%;
}

.box {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: var(--text);
  color: white;
  padding: var(--spacing);
  z-index: 10;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

  transform: translateY(100%);
  transition: transform var(--fast);
}

.slideIn[data-visible="true"] .box {
  transform: translateY(0);
}

.slideIn .form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.slideIn .form__input,
.slideIn .form__label-text {
  color: white;
}

.placeholder {
  padding: var(--spacing);
  display: none;
}

.slideIn[data-visible="true"] .placeholder {
  display: block;
}
